@import url(https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700|Source+Sans+Pro:300,300i,400,400i,600);
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
  border: none
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input { /* 1 */
  overflow: visible;

}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
html {
  font-size: 20px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}
#root {
  height: 100%;
}


/** Typography
 *
 */

h1, h2, .callout {
  font-family: 'Libre Baskerville', serif;
}

h1 {
  font-size: 2.27rem;
  line-height: 2.8rem;
  font-weight: bold;
  color: var(--app-primary-text);
  pointer-events: all;
}
h2 {
  font-size: 2.4rem;
  line-height: 2.4rem;
  font-weight: bold;
  pointer-events: all;
  margin: 1rem 0;
}
h3 {
  font-family: 'Source Sans Pro';
  font-size: 1.6rem;
  /* text-transform: uppercase; */
  font-weight: 300;
  font-style: italic;
  color: var(--app-primary-text);
  margin: 4.20rem 0 1.15rem;
  letter-spacing: 1px;
}
h3:first-child {
  margin-top: 1rem;
}
h4 {
  font-weight: bold;
  font-size: 1.75rem;
  font-weight: 500;
  margin: 1.5rem 0;
  font-family: 'Source Sans Pro', sans-serif;
}

h2 > a, h3 > a {
  color: inherit;
  text-decoration: none;
  pointer-events: all;
}

a:hover {
  text-decoration: underline;
}

h1,h2,h3,h4,li,p,span,form,button{
  pointer-events: all;
}

button {
  cursor: pointer;
  font-family: 'libre Baskerville', serif;
}

.big-number {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: bold;
  font-family: 'Libre Baskerville', serif;
  pointer-events: all;
}

#goalsNav {
  display: flex;
  flex-direction: column;
  text-align: right;
  border-top: 1px solid black;
  border-right: 1px solid black;
  height: 100%;
  width: 100%;
}
#goalsNav a {
  margin: 1rem 3rem 1rem;
}
#edit {
  width: 2rem;
  margin: .6rem;
}

p {
  margin: 0.5rem 0 1.5rem;
}
span.example {
  font-style: italic;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 700px) {
  html {
    font-size:18px;
  }
}


/** Layout **/
.container {
  display: flex;
  flex-direction: row-reverse;
  min-height: 100%;
}

.content {
  flex-grow: 1;
}

@media only screen and (max-width:800px) {
  .content-container {
    width: 98%;
  }
}

.wrapper {
  flex-grow: 0;
  position: relative;
  overflow: visible;
  height: 100vh;
  min-width: 140px;
}

#nav-container {
  text-align: right;
  padding-bottom: 1rem;
  background-color: white;
}

nav ul {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  margin: 0;
  padding: 0;
  z-index: 2;
}
nav li#today-link {
  padding-top: 1.5rem;
  margin-bottom: 1.7rem;
}
nav li a {
  display: block;
  margin-top: 0.3rem;
  padding: 0.3rem 40px 0.3rem 0;
  text-decoration: none;
  color: #111a3d;
}
nav li#today-link a {
  color: white;
}
nav li a.active {
  border-right: 18px solid #111a3d;
  padding-right: 22px;
  text-decoration: underline;
}
nav li#today-link a.active {
}

nav button {
  padding-right: 40px;
  text-transform: lowercase;
  font-size: 0.8rem;
  margin-top: 1.2rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}


#today-blob-container {
  position: absolute;
  pointer-events: all;
  width: 140%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 1;
}
#percentageBoxContainer {
  position:absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  overflow:hidden;
}

#percentageBoxPaper {
  position: absolute;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 1.2s linear;
  transition: all 1.2s linear;
}

#percentageBox {
  display: grid;
  grid-template-areas: 'a a' 'b c';
  background-color: rgba(255,255,255,.95);
  border: 3px solid #aaaaaa;
  grid-gap: .5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  margin: 8px;
}
#percentageBox-current-percent {
  color: black;
  font-size: 2rem;
  line-height: 2rem;
  font-family: 'Source Sans Pro', sans-serif;
  grid-area: a;
  text-align: center;
}
#percentageBox button {
  background-color: rgba(255,255,255,.2);
  font-size: 0.9rem;
  font-family: 'Libre Baskerville', serif;
  padding: .3rem;
  border: 1px dashed white;
  color:white;
}
#percentageBox button.active {
  border: 1px solid black;
  color: black;
}
#signIn {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.goalListItem {
  position: relative;
  padding: 1rem 0;
}
.goalListItem.expanded {
  margin-bottom: 2.5rem;
}
.goal-grid {
  display: grid;
  grid-template-columns: 6rem auto;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
     -moz-column-gap: 2rem;
          column-gap: 2rem;
  grid-row-gap: .25rem;
  row-gap: .25rem;
  grid-template-areas: 'left right'
}
.goal-grid.multi {
  grid-template-areas: 'left-top right-top' 'left-bottom right-bottom';
}
.goal-grid.activity{
  color: #a9a9a9;
}
.grid-left {
  grid-area: left;
  text-align: right;
}
.grid-right {
  grid-area: right;
  text-align: left;
}

.activity-list {
  padding: 0;
  margin: 1.3rem 0;
}
.dot-and-description {
  display: flex;
  flex-direction: row;
}
.dot-and-description img {
  padding: 0 1rem 0 0;
}
.ticks {
  text-align: right;
  font-size: .66rem;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-self: end;
  justify-content: flex-end;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding-top: 0.3rem;
}
.grid-left-top {
  grid-area: left-top;
  text-align: right;
}
.grid-right-top {
  grid-area: right-top;
  text-align: left;
}
.grid-right-bottom {
  grid-area: right-bottom;
  text-align: left;
  color: #a9a9a9
}
.grid-left-bottom {
  grid-area: left-bottom;
  text-align: right;
}
h2{
  margin-left: 8rem;
  padding: 2rem 0 1rem;
}
.title {
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
}
.tick {
  height: .5rem;
  width: .5rem;
  background-color: #a9a9a9;
  border-radius: 50%;
  display: inline-block;
  margin: .15rem;
}
#addGoal-modal {
  position: fixed;
  bottom: 5rem;
  right: 12rem;
  border: 2px solid #a9a9a9;
  display: flex;
  flex-direction: row;
  border-radius: 1rem;
  overflow: hidden;
  background: white;
  right: 14rem;
  color: #a9a9a9
}
#modal-left{
  display: flex;
  flex-direction: column;
}
#modal-left > button {
  height: 2.5rem;
  background: none;
  border-right: 1px solid #a9a9a9;
  flex-grow: 1
}
#modal-right{
  display: grid;
  grid-template-areas: "a b" "c c";
  grid-template-columns: 16rem 5rem;
  grid-column-gap: 2rem;
  grid-row-gap: .5rem;
  margin: .5rem
}
#modal-right input {
  width: 100%
}
#new-goal-description-input-div {
  grid-area: c;
}
#new-goal-description {
  border-bottom: 1px solid #a9a9a9;
}
#new-goal-due-date-input-div {
  text-align: right
}
#new-goal-title {
  border-bottom: 1px solid #a9a9a9;
}
#modal-right  label {
  display: flex;
  flex-direction: column;
  font-size: .7rem;
}
.date-input {
  border-bottom: 1px solid #a9a9a9;
  text-align: right;
  width: 9ch;
}
.plussign {
  border-bottom: 1px solid #a9a9a9;
  height: auto
}
.commit {
  border-bottom: 1px solid #a9a9a9;
  height: auto;
}
.paper-airplane {
  width: 55%;
  height: auto;
}
.editGoal {
  border: 2px solid #a9a9a9;
  display: flex;
  flex-direction: row;
  border-radius: 1rem;
  overflow: hidden;
  background: white;
  color: #a9a9a9;
  width: 88%;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: 0.4rem;
}
.editGoal-left{
  display: flex;
  flex-direction: column;
  width: 3.5rem
}
.editGoal-left > button {
  height: 2.5rem;
  background: none;
  border-right: 1px solid #a9a9a9;
  flex-grow: 1
}
.editGoal-right{
  display: grid;
  grid-template-areas: "a b" "c c";
  grid-template-columns: 6fr 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 1rem;
  margin: 1rem;
  
}
.editGoal-right input {
  width: 100%
}
.edit-goal-due-date-input-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.edit-goal-description-input-div {
  grid-area: c;
}
.edit-goal-description {
  border-bottom: 1px solid #a9a9a9;
}
.edit-goal-due-date-input-div {
  text-align: right;
}
.edit-goal-title {
  border-bottom: 1px solid #a9a9a9;
}
#editGoal-right  label {
  display: flex;
  flex-direction: column;
  font-size: .7rem;
}
.goal-button {
  width: auto;
  height: auto;
  padding: .25rem 2rem;
  background-color: #e7e7e7;
  margin-left: 2rem;
  font-size: 1.2rem;
  border-radius: .3rem;
}

.buttons {
  position: absolute;
  left: 100%;
  height: 100%;
  top: 0;
}
.buttons > button {
  position: absolute;
  left: 100%;
  padding: 0;
  bottom: 0;
}
.buttons > .edit-button {
  top: 0.6rem;
  bottom: unset;
}
.buttons > button > img {
  padding: 0.5rem;
}
.goal {
  position: relative;
}
.goal-container {
  padding-bottom: 5rem;
  max-width: 700px;
  margin: auto;
}
.dot-and-description {

}
#buttons {
  position: fixed;
  bottom: 1.5rem;
  right: 14rem;
}
#homePage {
  position: relative;
}
#PercentagePaperBackground {
  position:absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  overflow:hidden;
}

#paperCanvas {
  position: absolute;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 1.2s linear;
  transition: all 1.2s linear;
}
#today-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#percent {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 1rem;
  z-index: 2;
}
#percentages {
  display: grid;
  grid-template-areas: 'a a' 'b c';
  background-color: rgba(255,255,255,.9);
  border: 3px solid #aaaaaa;
  grid-gap: .5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
#current-percent {
  color: black;
  font-size: 2rem;
  line-height: 2rem;
  font-family: 'Source Sans Pro', sans-serif;
  grid-area: a;
  text-align: center;
}
#percentages button {
  background-color: rgba(255,255,255,.2);
  font-size: 0.9rem;
  font-family: 'Libre Baskerville', serif;
  padding: .3rem;
  border: 1px dashed #aaaaaa;
  color: #aaaaaa;
}
#percentages button.active {
  border: 1px solid black;
  color: black;
}
#days-of-week {
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

#days-of-week > div {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}

div.heading {
  margin-top: 1rem;
  color: #aaa;
}
div.heading.today {
  color: black;
  background-color: rgba(255,255,255,0.9);
}
div.heading span {
  font-size: 2.5rem;
  line-height: 3.5rem;
  font-family: 'Libre Baskerville', serif;
}

div.stats > div{
  margin: 1.8rem 0 2rem;
}
div.stats > div:last-child {
  margin-top: 5rem;
}

div.stats p {
  font-size: 1.4rem;
  margin: 0.3rem 0 0;
  /* font-family: 'Source Sans Pro', sans-serif; */
}

@media only screen and (max-width: 800px) {
  #days-of-week {
    grid-template-columns: 1fr 1fr 1fr;
  }
  #days-of-week > div:nth-child(1) {
    display: none;
  }
}
