.goalListItem {
  position: relative;
  padding: 1rem 0;
}
.goalListItem.expanded {
  margin-bottom: 2.5rem;
}
.goal-grid {
  display: grid;
  grid-template-columns: 6rem auto;
  column-gap: 2rem;
  row-gap: .25rem;
  grid-template-areas: 'left right'
}
.goal-grid.multi {
  grid-template-areas: 'left-top right-top' 'left-bottom right-bottom';
}
.goal-grid.activity{
  color: #a9a9a9;
}
.grid-left {
  grid-area: left;
  text-align: right;
}
.grid-right {
  grid-area: right;
  text-align: left;
}

.activity-list {
  padding: 0;
  margin: 1.3rem 0;
}
.dot-and-description {
  display: flex;
  flex-direction: row;
}
.dot-and-description img {
  padding: 0 1rem 0 0;
}
.ticks {
  text-align: right;
  font-size: .66rem;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-self: end;
  justify-content: flex-end;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding-top: 0.3rem;
}
.grid-left-top {
  grid-area: left-top;
  text-align: right;
}
.grid-right-top {
  grid-area: right-top;
  text-align: left;
}
.grid-right-bottom {
  grid-area: right-bottom;
  text-align: left;
  color: #a9a9a9
}
.grid-left-bottom {
  grid-area: left-bottom;
  text-align: right;
}
h2{
  margin-left: 8rem;
  padding: 2rem 0 1rem;
}
.title {
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
}
.tick {
  height: .5rem;
  width: .5rem;
  background-color: #a9a9a9;
  border-radius: 50%;
  display: inline-block;
  margin: .15rem;
}
#addGoal-modal {
  position: fixed;
  bottom: 5rem;
  right: 12rem;
  border: 2px solid #a9a9a9;
  display: flex;
  flex-direction: row;
  border-radius: 1rem;
  overflow: hidden;
  background: white;
  right: 14rem;
  color: #a9a9a9
}
#modal-left{
  display: flex;
  flex-direction: column;
}
#modal-left > button {
  height: 2.5rem;
  background: none;
  border-right: 1px solid #a9a9a9;
  flex-grow: 1
}
#modal-right{
  display: grid;
  grid-template-areas: "a b" "c c";
  grid-template-columns: 16rem 5rem;
  grid-column-gap: 2rem;
  grid-row-gap: .5rem;
  margin: .5rem
}
#modal-right input {
  width: 100%
}
#new-goal-description-input-div {
  grid-area: c;
}
#new-goal-description {
  border-bottom: 1px solid #a9a9a9;
}
#new-goal-due-date-input-div {
  text-align: right
}
#new-goal-title {
  border-bottom: 1px solid #a9a9a9;
}
#modal-right  label {
  display: flex;
  flex-direction: column;
  font-size: .7rem;
}
.date-input {
  border-bottom: 1px solid #a9a9a9;
  text-align: right;
  width: 9ch;
}
.plussign {
  border-bottom: 1px solid #a9a9a9;
  height: auto
}
.commit {
  border-bottom: 1px solid #a9a9a9;
  height: auto;
}
.paper-airplane {
  width: 55%;
  height: auto;
}
.editGoal {
  border: 2px solid #a9a9a9;
  display: flex;
  flex-direction: row;
  border-radius: 1rem;
  overflow: hidden;
  background: white;
  color: #a9a9a9;
  width: 88%;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: 0.4rem;
}
.editGoal-left{
  display: flex;
  flex-direction: column;
  width: 3.5rem
}
.editGoal-left > button {
  height: 2.5rem;
  background: none;
  border-right: 1px solid #a9a9a9;
  flex-grow: 1
}
.editGoal-right{
  display: grid;
  grid-template-areas: "a b" "c c";
  grid-template-columns: 6fr 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 1rem;
  margin: 1rem;
  
}
.editGoal-right input {
  width: 100%
}
.edit-goal-due-date-input-div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.edit-goal-description-input-div {
  grid-area: c;
}
.edit-goal-description {
  border-bottom: 1px solid #a9a9a9;
}
.edit-goal-due-date-input-div {
  text-align: right;
}
.edit-goal-title {
  border-bottom: 1px solid #a9a9a9;
}
#editGoal-right  label {
  display: flex;
  flex-direction: column;
  font-size: .7rem;
}
.goal-button {
  width: auto;
  height: auto;
  padding: .25rem 2rem;
  background-color: #e7e7e7;
  margin-left: 2rem;
  font-size: 1.2rem;
  border-radius: .3rem;
}

.buttons {
  position: absolute;
  left: 100%;
  height: 100%;
  top: 0;
}
.buttons > button {
  position: absolute;
  left: 100%;
  padding: 0;
  bottom: 0;
}
.buttons > .edit-button {
  top: 0.6rem;
  bottom: unset;
}
.buttons > button > img {
  padding: 0.5rem;
}
.goal {
  position: relative;
}
.goal-container {
  padding-bottom: 5rem;
  max-width: 700px;
  margin: auto;
}
.dot-and-description {

}